import { FC, useEffect } from 'react'

import { captureException } from '@sentry/browser'
import {
  FindLearnerTaskQuery,
  FindOrCreateLearnerTaskTrackingMutation,
  FindOrCreateLearnerTaskTrackingMutationVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'

import CoursePlayerView from 'src/components/Learner/Courses/CourseCell/CoursePlayer/TaskCell/CoursePlayerView/CoursePlayerView'
import { SetTaskStatus } from 'src/components/Learner/Courses/types'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { FIND_OR_CREATE_TASK_TRACKING } from 'src/lib/queries/learner/learnerTracking'
import { useAuth } from 'src/Providers'

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
})

export const QUERY = gql`
  query FindLearnerTaskQuery($id: Int!) {
    learnerTask: learnerTask(id: $id) {
      id
      name
      video
      text
      rank
      processMapId
      showQuizAnswers
      textLayout
      embedURL
      quiz {
        id
        question
        choices
        rank
      }
      learnerTaskTrackings(latestAttempt: true) {
        id
        progressData
        status
        learnerCourseTrackingId
        learnerCourseTracking {
          status
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export type Props = CellSuccessProps<FindLearnerTaskQuery> & {
  setTaskStatus: SetTaskStatus
}

export const Success: FC<Props> = ({
  learnerTask,
  setTaskStatus,
  queryResult: { refetch },
}) => {
  const { currentUser } = useAuth()
  const clientId = currentUser.membershipData.clientId
  const membershipId = currentUser?.membershipData?.id

  const [findOrCreateLearnerTaskTracking] = useMutation<
    FindOrCreateLearnerTaskTrackingMutation,
    FindOrCreateLearnerTaskTrackingMutationVariables
  >(FIND_OR_CREATE_TASK_TRACKING, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      captureException(error)
    },
  })

  const inSupportMode = currentUser.isSupportMode
  useEffect(() => {
    if (!inSupportMode) {
      findOrCreateLearnerTaskTracking({
        variables: {
          input: {
            learnerTaskId: learnerTask.id,
            membershipId,
            progressData: [],
            status: 'IN_PROGRESS',
          },
        },
      })
    }
  }, [
    clientId,
    findOrCreateLearnerTaskTracking,
    inSupportMode,
    learnerTask.id,
    membershipId,
  ])

  return (
    <CoursePlayerView
      task={learnerTask}
      setTaskStatus={setTaskStatus}
      isPreview={false}
    />
  )
}
