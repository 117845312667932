import { FC, Dispatch, SetStateAction } from 'react'

import Button from '@mui/material/Button'
import { captureException } from '@sentry/browser'
import { LearnerCourse } from 'types/graphql'

import { Redirect, Link, routes } from '@redwoodjs/router'
import type { CellFailureProps } from '@redwoodjs/web'

import { default as EmptyComponent } from 'src/components/Empty/Empty'
import CoursePlayer from 'src/components/Learner/Courses/CourseCell/CoursePlayer/CoursePlayer'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { useAuth } from 'src/Providers'

interface Props {
  learnerCourse: LearnerCourse
  selectedTaskId: number
  setSelectedTaskId: Dispatch<SetStateAction<number>>
  activityId: number
  taskId: number
}

export const QUERY = gql`
  query FindLearnerCourseQuery($id: Int!) {
    learnerCourse: learnerCourse(id: $id) {
      id
      name
      status
      heroStorageObject {
        id
        downloadUrl
      }
      createdBy
      learnerActivities(status: PUBLISHED) {
        id
        name
        learnerTasks(status: PUBLISHED) {
          id
          name
          rank
          text
          video
          processMapId
          embedURL
          learnerActivityId
          quiz {
            id
          }
          learnerTaskTrackings {
            status
            learnerCourseTrackingId
          }
        }
      }
      learnerCourseTrackings {
        id
      }
    }
  }
`
export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'cache-and-network',
})
export const Loading = () => <LoadingSpinner />

export const Empty = () => <Redirect to="/404" />

export const Failure = ({ error }: CellFailureProps) => {
  captureException(error)
  return <div style={{ color: 'red' }}>Error: {error.message}</div>
}

export const Success: FC<Props> = ({ learnerCourse, activityId, taskId }) => {
  const { hasRole } = useAuth()

  return (
    <div className="flex h-[calc(100%-64px)] w-full overflow-auto">
      {learnerCourse.learnerActivities.length > 0 ? (
        <CoursePlayer
          course={learnerCourse}
          activityId={activityId}
          taskId={taskId}
        />
      ) : (
        <EmptyComponent
          className="my-20"
          defaultIcon
          title="Whoops"
          description="Looks like there are no published chapters in this course."
        >
          {hasRole(['ADMIN', 'OWNER', 'EDITOR']) ? (
            <Link to={routes.editLearnerCourse({ id: learnerCourse.id })}>
              <Button className="capitalize" variant="outlined">
                Edit Course
              </Button>
            </Link>
          ) : (
            <Link to={routes.learnerCategories()}>
              <Button className="capitalize" variant="outlined">
                Back to Learn Categories
              </Button>
            </Link>
          )}
        </EmptyComponent>
      )}
    </div>
  )
}
